"use client";

import { createContext, useContext, useRef } from 'react';
import EnquiryForm from '../components/enquiry-form';

const FormContext = createContext(null);

export function FormProvider({ children }) {
  const formRef = useRef(null);

  const openForm = () => {
    formRef.current?.open();
  };

  const closeForm = () => {
    formRef.current?.close();
  };

  return (
    <FormContext.Provider value={{ openForm, closeForm }}>
      {children}
      <EnquiryForm ref={formRef} />
    </FormContext.Provider>
  );
}

export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
}; 