"use client";

import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import Image from "next/image";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";

const EnquiryForm = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const [consentChecked, setConsentChecked] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Expose methods to parent
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  // Animation variants
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8, y: 20 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.5,
        bounce: 0.3,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: 20,
      transition: { duration: 0.3 },
    },
  };

  const inputVariants = {
    focus: { scale: 1.02, transition: { duration: 0.2 } },
  };

  const mutation = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/api/get-callback`,
          {
            name: data.name,
            phoneNumber: data.phone,
            description: data.details || "Contact me",
          }
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return error.response.data;
        }
        throw error;
      }
    },
    onSuccess: (data) => {
      toast.success(data.message);
      reset();
      setIsOpen(false);
    },
    onError: (error) => {
      toast.error(error.message);
      console.error("Error submitting enquiry:", error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={overlayVariants}
          className="fixed inset-0 z-[99999] flex items-center justify-center bg-black/60 p-6 sm:p-10 
            overflow-y-auto backdrop-blur-sm"
        >
          <motion.div
            ref={modalRef}
            variants={modalVariants}
            className="bg-gradient-to-br from-primary400 to-primary300 font-sans p-8 sm:p-12 
              shadow-2xl max-w-[1000px] grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-12 
              relative rounded-2xl border border-white/10"
          >
            {/* Close button */}
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsOpen(false)}
              className="absolute -top-3 -right-3 z-[100] p-2 rounded-full 
                bg-white shadow-lg hover:bg-gray-100 transition-colors"
            >
              <Image
                src="/close.svg"
                alt="Close"
                width={16}
                height={16}
                className="opacity-70"
              />
            </motion.button>

            {/* Left Section */}
            <div className="w-full flex flex-col justify-between gap-8">
              <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-3xl md:text-4xl font-bold text-tertiary leading-tight"
              >
                Your maintenance problem is no longer yours anymore
              </motion.h1>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <p className="text-tertiary/90 text-lg mb-4">
                  Download now on:
                </p>
                <div className="flex items-center gap-4 md:gap-6">
                  <motion.a
                    href="https://play.google.com/store/apps/details?id=com.main10.partner"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    target="_blank"
                    rel="noreferrer"
                    className="w-[132px] md:w-[152px] h-[44px] md:h-[50px]"
                  >
                    <Image
                      src={"/banner-app-store.svg"}
                      width={152}
                      height={50}
                      alt="play"
                    />
                  </motion.a>

                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="relative group"
                  >
                    <Image
                      src="/play-store-white.svg"
                      width={152}
                      height={50}
                      alt="play-store"
                      className="w-[140px] md:w-[152px] hover:opacity-90 transition-opacity opacity-50"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                      <span className="text-white font-medium">
                        Coming Soon
                      </span>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            </div>

            {/* Right Section - Form */}
            <div className="w-full">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <motion.div variants={inputVariants} whileFocus="focus">
                  <input
                    type="text"
                    {...register("name", { required: "Name is required" })}
                    className="w-full p-3 rounded-xl outline-none border-2 border-white/20 
                      text-tertiary placeholder-tertiary/70 font-medium bg-white/10 
                      backdrop-blur-sm transition-all focus:border-white/40
                      hover:border-white/30"
                    placeholder="Your Name"
                  />
                  {errors.name && (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-red-400 mt-1 text-sm"
                    >
                      {errors.name.message}
                    </motion.p>
                  )}
                </motion.div>
                <motion.div variants={inputVariants} whileFocus="focus">
                  <input
                    type="text"
                    {...register("phone", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^[6-9]\d{9}$/,
                        message: "Please enter a valid Indian phone number",
                      },
                      validate: {
                        validLength: (value) =>
                          !value ||
                          value.length === 10 ||
                          "Phone number must be 10 digits",
                      },
                    })}
                    className="w-full p-3 rounded-xl outline-none border-2 border-white/20 
                      text-tertiary placeholder-tertiary/70 font-medium bg-white/10 
                      backdrop-blur-sm transition-all focus:border-white/40
                      hover:border-white/30"
                    placeholder="Phone Number (e.g., 9876543210)"
                    inputMode="numeric"
                    onKeyDown={(e) => {
                      // Allow: backspace, delete, tab, escape, enter
                      if (
                        [
                          "Backspace",
                          "Delete",
                          "Tab",
                          "Escape",
                          "Enter",
                          "ArrowLeft",
                          "ArrowRight",
                          "Home",
                          "End",
                        ].includes(e.key)
                      ) {
                        return;
                      }

                      // Block non-numeric input
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }

                      // Prevent input beyond 10 digits
                      if (
                        e.target.value.length >= 10 &&
                        e.key !== "Backspace"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.phone && (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-red-400 mt-1 text-sm"
                    >
                      {errors.phone.message}
                    </motion.p>
                  )}
                </motion.div>
                <motion.div variants={inputVariants} whileFocus="focus">
                  <textarea
                    {...register("details")}
                    className="w-full p-3 rounded-xl outline-none border-2 border-white/20 
                      text-tertiary placeholder-tertiary/70 font-medium bg-white/10 
                      backdrop-blur-sm transition-all focus:border-white/40
                      hover:border-white/30 min-h-[120px] resize-none"
                    placeholder="Tell us more (Optional)"
                  />
                </motion.div>
                <motion.div
                  variants={inputVariants}
                  className="flex items-start gap-2"
                >
                  <input
                    type="checkbox"
                    id="consent"
                    checked={consentChecked}
                    onChange={(e) => setConsentChecked(e.target.checked)}
                    className="mt-1 w-4 h-4 rounded border-white/20 bg-white/10 text-primary 
      focus:ring-primary cursor-pointer"
                    required
                  />
                  <label
                    htmlFor="consent"
                    className="text-sm text-tertiary/90 cursor-pointer"
                  >
                    I agree to the{" "}
                    <a
                      href="/terms-and-conditions"
                      className="underline hover:text-white"
                    >
                      Terms & Conditions
                    </a>{" "}
                    and consent to being contacted regarding my enquiry
                  </label>
                </motion.div>

                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full py-3 px-6 bg-white text-primary font-semibold 
                    rounded-xl hover:bg-white/90 transition-colors shadow-lg
                    disabled:opacity-70 disabled:cursor-not-allowed"
                  disabled={mutation.isLoading || !consentChecked}
                >
                  {mutation.isLoading ? (
                    <div className="flex items-center justify-center gap-2">
                      <div
                        className="w-5 h-5 border-2 border-primary/30 border-t-primary 
                        rounded-full animate-spin"
                      />
                      <span>Submitting...</span>
                    </div>
                  ) : (
                    "Submit Enquiry"
                  )}
                </motion.button>
              </form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

EnquiryForm.displayName = "EnquiryForm";

export default EnquiryForm;
