export const NAV_LINKS = [
  "Home",
  "How it works",
  "Pricing Plans",
  "Testimonials",
  "Insights",
  "FAQs",
];

export const HERO_DATA = {
  assurance: "Fixing in 2 hours",
  heading: "Property Maintenance Services starting from only",
  price: "₹199 Room/Month*",
  terms: "*T&C Applied",
  download: "download now on",
};

export const COMPANIES = [
  "Forbes",
  "Inc42",
  "Economic Times",
  "Money Control",
  "YourStory",
];

export const HOW_IT_WORKS = {
  heading: "How it works",
  cards: [
    {
      one: {
        id: 1,
        title: "Complaint Raised",
        description:
          "The property owner and tenant can raise a complaint through our app.",
      },
      two: {
        id: 2,
        title: "Executive Assigned",
        description:
          "A executive is assigned within minutes and OTP will be verified to start the work by our team.",
      },
      three: {
        id: 3,
        title: "Complaint Resolved",
        description:
          "After Inspection of the issue our Skilled Executive fixes it in 2 HOURS.",
      },
    },
  ],
};

export const OUR_PLANS = {
  heading: "Our Plans",
};

export const DEMO = {
  heading: "With advance and pre-built features, Just as you",
  headingPart: "need",
  image: "/demo.png",
};

export const TESTIMONIALS = {
  heading: "What our customers are saying about us",
};

export const FAQS = {
  heading: "Frequently Asked Questions",
};

export const BANNER_ONE = {
  heading: "Need Assistance in Property Maintenance?",
  description: "Fill the form and schedule a visit.",
  buttonText: "Get a CALL BACK",
};

export const BLOG = {
  heading: "Explore our blogs to learn more",
};

export const BANNER_TWO = {
  heading: "Better Experience with OUR  App",
  description:
    "Download our App from Play-store & App store for customized property maintenance solutions.",
};
